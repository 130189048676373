import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"
import Title from "../../components/title"
// import Hero from "../../blocks/Hero"
import { FixedBanner } from "../../blocks/Banner"
import TextWithImage from "../../blocks/TextWithImage"

const Fintechs = () => (
  <Layout>
    {/* <Hero
      data={{
        title: "Paybase for Fintechs",
        copy:
          '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur non lorem ultrices lobortis. Donec in mauris sed eros volutpat tempor eget nec purus. Duis sagittis tempor volutpat. Pellentesque ornare justo in felis efficitur, non semper nisi imperdiet. Morbi rutrum tellus nisl."',
        companyLogo: {
          src: "/who-is-it-for/marketplace/airbnb.png",
          alt: "John Smith, Product manager at Fast banana",
          copy: "John Smith, Product manager at Fast banana",
        },
        image: {
          src: "/who-is-it-for/sharing-economy/img1.png",
          alt: "placeholder image",
        },
        color1: "lavender",
        color2: "darkgreen",
        color3: "rose",
      }}
    /> */}
    <Helmet>
      <title>Paybase - FinTechs</title>
      <meta property="og:title" content="Paybase - FinTechs" />
    </Helmet>
    <TextWithImage
      data={{
        title: "Paybase for FinTechs",
        content:
          "Route and split payments between multiple parties dependent on your business parameters. Easily create accounts for all parties in your payments flow, whilst instantly receiving commission fees on transactions (should your business choose to). Full regulatory cover, compliance and anti-money laundering framework provided.",
        image: {
          src: "/who-is-it-for/fintechs/img1.svg",
          alt: "placeholder alt",
        },
        links: [
          { copy: "Get in touch", to: "https://trustshare.co" },
          {
            copy: "Developer Centre",
            to: "https://developers.paybase.io/",
            color: "white",
            bg: "black",
            shadow: "white",
            hoverColor: "black",
          },
        ],
      }}
      options={{
        titleColor: "blue",
        copyColor: "grey",
        backgroundColor: "lightblue",
        padding: "3rem",
      }}
    />
    <Title copy="Using Paybase’s capabilities on your FinTech platform?" />
    <TextWithImage
      data={{
        title: "Funds Custody & Regulatory Cover_",
        content:
          "Paybase is an FCA-regulated financial institution. Our Electronic Money Licence authorises us to hold our clients’ and their users’ fiat funds safely in a segregated client-safeguarded account. We’ll cover your regulatory needs, relieving you from having to manoeuvre compliance hurdles and giving you the space to focus on your all-important product innovation.",
        image: {
          src: "/who-is-it-for/fintechs/img2.svg",
          alt: "placeholder alt",
        },
        links: [{ copy: "Find out more", to: "/what-we-offer#regulatory" }],
      }}
      options={{ titleColor: "darkgreen" }}
    />
    <TextWithImage
      data={{
        title: "Banking Lite_",
        content:
          "Create unique account numbers and sort codes to facilitate banking lite functionality. Allow platform users to put funds into a user account which can then be stored, transferred A2A and/or paid out to third parties. An example of this might be a P2P payments app or an investment service.",
        image: {
          src: "/who-is-it-for/fintechs/img3.svg",
          alt: "placeholder alt",
        },
        links: [{ copy: "Find out more", to: "/what-we-offer/#banking" }],
      }}
      options={{ isTextRight: true, titleColor: "blue" }}
    />
    <TextWithImage
      data={{
        title: "Referral Campaigns_",
        content:
          "You may wish to use a referral scheme to improve user acquisition and engagement on your FinTech platform. If you reward your users £3 for every user they get to sign up to the service, this could be credited to their account instantly from a ‘rewards’ account that you, as the platform, run. A referral scheme such as this not only incentivises new users to sign up to your service, but to also use it, as they have funds to use and contacts to interact with.",
        image: {
          src: "/who-is-it-for/fintechs/img4.svg",
          alt: "placeholder alt",
        },
        links: [{ copy: "Find out more", to: "/what-we-offer#reward" }],
      }}
      options={{ titleColor: "darkgreen" }}
    />
    <FixedBanner />
  </Layout>
)

export default Fintechs
